// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    background-image: url("../images/bg-pattern-2.png");
    background-size: cover;
}

// authentication pages background
/*body.authentication-bg {
    background-image: url("../images/bg.png");
    background-size: cover;
}*/

// Logout page
.logout-icon {
    width: 140px;
}